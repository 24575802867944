import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { getCategories } from '../../../actions/appointment';
import { createAllCategoriesSelector } from '../../../reducers/appointment';
import { createLoadingSelector } from '../../../reducers/api';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Layout from '../../../components/v2/Layout';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  categoryImage: {
    width: '50%'
  },
  categoryName: {
    margin: 8,
    fontWeight: 'bold',
    textAlign: 'center',
    minHeight: 35,
    lineHeight: 1.3,
  },
  header: {
    paddingTop: 15,
    textAlign: 'center'
  }
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const categories = useSelector(createAllCategoriesSelector);
  const { user } = useSelector(state => state.session);
  const isLoading = useSelector(createLoadingSelector(['appointment/categories/get']));

  React.useEffect(() => {
    if (user) {
      dispatch(getCategories(null, true));
    }
  }, [user]);

  const onClick = (category) => () => {
    window.logEvent('select_content', { content_type: 'consult_category', item_id: category.id + '', item_name: category.name });
    navigate(`/membership/consult/chooseConsultant?categoryId=${category.id}`);
  }

  return (
    <Layout bg="bg1" title="ต้องการพบแพทย์ด้านใด" loading={isLoading && !categories.length}>
      <Grid container spacing={2}>
        {categories.map(category => (
          <Grid item xs={6} sm={6} md={3} key={category.id}>
            <Card className={classes.card} onClick={onClick(category)}>
              <CardMedia component='img' image={category.image} className={classes.categoryImage} />
              <Typography variant="subtitle1" component="p" color="textPrimary" className={classes.categoryName}>
                {category.name}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Layout>
  );
};
